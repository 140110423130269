import React, { useContext } from "react";
import {
    List,
    Show,
    Datagrid,
    NumberField,
    TextField,
    ReferenceArrayField,
    EditButton,
    ShowButton,
    useShowContext,
    Edit,
    Create,
    SimpleForm,
    TextInput,
    NumberInput,
    ArrayInput,
    ReferenceInput,
    SimpleFormIterator,
    SimpleShowLayout,
    useRecordContext,
    useShowController,
    TopToolbar,
    Labeled} from 'react-admin';
import { Link } from 'react-router-dom';
import { EditAttributes } from '@mui/icons-material';
import { BluehillPagination } from './bluehillPagination';

import SortableIterator from './components/SortableIterator';
import AutocompleteInput from './components/BaseAutocompleteInput';
import { ShowActionHistoryButton } from "./components/RobotActionButtons.js";

const filters = [
    <TextInput label="Configuration" source="configuration" alwaysOn />,
    <TextInput label="Task name" source="partialName" alwaysOn />,
    <TextInput label="Task key" source="partialTaskKey" alwaysOn />,
    <TextInput label="Task ID" source="ids" alwaysOn />,
];

const actionOptionRenderer = action => {
    if(!action) {
        return '';
    } else {
        return action.id + ": " + action.name 
            + " (Configuration " + (action.configuration == undefined ? "not set" : action.configuration) + ")";
    }
};

export const TaskList = props => (
    <List {...props} syncWithLocation perPage={50} sort={{field:'', order:''}} 
        filters={filters} filterDefaultValues={{ configuration:0 }} pagination={<BluehillPagination />}>
        <Datagrid bulkActionButtons={false} >
            <TextField source="id" />
            <TextField source="configuration" />
            <TextField source="name" style={{ wordBreak: 'break-word' }} />
            <TextField source="taskKey" style={{ wordBreak: 'break-word' }} />
            <ShowButton />
            <EditButton />
            <EditButton label="Adjust Actions" resource="/robot_task_operation_executions" icon={<EditAttributes />} />
        </Datagrid>
    </List>
);

export const TaskCreate = props => (
    <Create {...props}>
        <SimpleForm>
            <NumberInput source="configuration" />
            <TextInput source="name" />
            <ReferenceInput source="taskKey" reference="robot_task_keys">
                <AutocompleteInput optionText="taskKey"
                    filterToQuery={searchText => ({ partialTaskKey: searchText })}
                    fullWidth
                    shouldRenderSuggestions={searchText => searchText.trim().length >= 3} />
            </ReferenceInput>
            <ArrayInput source="actionIds" allowduplicates="true">
                <SimpleFormIterator TransitionProps={{ enter: false, exit: false }}>
                    <ReferenceInput reference="robot_actions" filterToQuery={searchText => ({ partialName: searchText, useConfigurationQuery: true })} fullWidth >
                        <AutocompleteInput optionText={actionOptionRenderer} shouldRenderSuggestions={searchText => searchText.trim().length >= 1} />
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Create>
);

function TaskShowActions() {
    const {
        record,
    } = useShowContext();
    return (
        <TopToolbar>
            <EditButton record={record} />
            {/* Add your custom actions */}
            <AdjustActionButton record={record} />
        </TopToolbar>
    );
}

function AdjustActionButton({ record, action, label = 'Adjust Actions' }) {
    return record ? (
        <EditButton
            component={Link}
            to={{
                pathname: `/robot_task_operation_executions/${record.id}`,
                state: {
                    ...record,
                    ...(action ? { actionIds: record.actionIds.filter(id => id === action.id)} : {} )
                }
            }}
            label={label}
            icon={<EditAttributes />}
        />
    ) : null;
}

const ShowRowContext = React.createContext(null);

function ColumnAdjustActionButton() {
    const record = useContext(ShowRowContext);
    const action = useRecordContext();
    return (
        <AdjustActionButton record={record} action={action} label="Adjust Action" />
    );
}

export const TaskShow = props => {
    const { record } = useShowController(props);
    return (
        <Show {...props} actions={<TaskShowActions />}>
            <SimpleShowLayout>
                <TextField source="id" />
                <TextField source="configuration" />
                <TextField source="name" />
                <TextField source="taskKey" />
                <ReferenceArrayField
                    reference="robot_actions"
                    source="actionIds"
                    label="Actions"
                    fullWidth>
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="id" />
                            <TextField source="name" />
                            <ShowButton />
                            <ShowActionHistoryButton />
                            <ShowRowContext.Provider value={record}>
                                <ColumnAdjustActionButton />
                            </ShowRowContext.Provider>
                        </Datagrid>
                </ReferenceArrayField>
            </SimpleShowLayout>
        </Show>
    );
};

export const TaskEdit = props => (
    <Edit {...props}>
        <SimpleForm>
            <Labeled label="Id" >
                <TextField source="id" />
            </Labeled>
            <Labeled label="Configuration" >
                <TextField source="configuration" />
            </Labeled>
            <TextInput source="name" />
            <ReferenceInput source="taskKey" reference="robot_task_keys">
                <AutocompleteInput optionText="taskKey"
                    filterToQuery={searchText => ({ partialTaskKey: searchText })}
                    fullWidth
                    shouldRenderSuggestions={searchText => searchText.trim().length >= 3} />
            </ReferenceInput>
            <ArrayInput source="actionIds" allowduplicates="true" >
                <SimpleFormIterator inline>
                    <ReferenceInput reference="robot_actions" >
                        <AutocompleteInput
                            optionText={actionOptionRenderer}
                            filterToQuery={searchText => ({ partialName: searchText, useConfigurationQuery: true })}
                            fullWidth shouldRenderSuggestions={searchText => searchText.trim().length >= 1}
                        />
                    </ReferenceInput>
                </SimpleFormIterator>
            </ArrayInput>
        </SimpleForm>
    </Edit>
);