import React from 'react';
import {
    Link,
} from 'react-admin';

import { ImageList, ImageListItem, ImageListItemBar, useMediaQuery } from '@mui/material';

import AuthImage from './AuthImage';

export default function ImageGallery({ data = [], link, source, subTitle, onClick }) {
    const isXSmall = useMediaQuery(theme => theme.breakpoints.up('xs'));
    const isSmall = useMediaQuery(theme => theme.breakpoints.up('sm'));
    const isMedium = useMediaQuery(theme => theme.breakpoints.up('md'));
    const isLarge = useMediaQuery(theme => theme.breakpoints.up('lg'));
    const isXLarge = useMediaQuery(theme => theme.breakpoints.up('xl'));
    const cols = getCols(isXSmall, isSmall, isMedium, isLarge, isXLarge);
    const src = typeof source === 'function' ? source : (record) => record[source];
    const title = typeof subTitle === 'function' ? subTitle : (subTitle ? (record) => record[subTitle] : null);
    if (!data?.length) return null;

    return (
        <ImageList cols={cols}>
            {data.map((item) => {
                const linkResult = typeof link === 'function' ? link(item) : link;
                const content = [
                    <AuthImage url={src(item)} width="100%" />,
                    title && <ImageListItemBar title={title(item)} />
                ];
                return (
                    <ImageListItem
                        key={item.id}
                        sx={{ alignItems: 'center', justifyContent: 'center', minHeight: '200px' }}
                        onClick={onClick ? () => onClick(item) : null}
                    >
                        {link ? (
                            <Link to={linkResult} sx={{ width: '100%', height: '100%' }}>
                                {content}
                            </Link>
                        ) : content}
                    </ImageListItem>
                );
            })}
        </ImageList>
    );
};

const getCols = (isXSmall, isSmall, isMedium, isLarge, isXLarge) => {
    if (isXLarge) {
        return 4;
    } else if (isLarge) {
        return 3;
    } else if (isMedium) {
        return 2;
    } else {
        return 1;
    }
}

const getColumnsByBreakpoint = (breakpoint) => {
    if (breakpoint === 'xs' || breakpoint === 'sm') {
        return 1;
    } else if (breakpoint === 'md') {
        return 2;
    } else if (breakpoint === 'lg') {
        return 3;
    } else {
        return 4;
    }
}

const getRowHeight = (cols) => {
    if (cols === 1) return 240;
    if (cols === 2) return 360;
    if (cols === 3) return 360;
    return 480;
};

const getBreakpoint = (theme) => {
    if (theme.breakpoints.down('xs')) {
        return 'xs';
    } else if (theme.breakpoints.down('sm')) {
        return 'sm';
    } else if (theme.breakpoints.down('md')) {
        return 'md';
    } else if (theme.breakpoints.down('lg')) {
        return 'lg';
    } else {
        return 'xl';
    }
}
