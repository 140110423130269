import { useState } from 'react';
import {
    Button,
    Link,
    useRecordContext,
} from 'react-admin';
import { History } from '@mui/icons-material';

export const ShowActionHistoryButton = () => {
    const record = useRecordContext();
    if (!record) return null;

    const filter = {
        'object.id': record.configuration ? record.configuration : 0,
        'object.type': 'ROBOT_CONFIGURATION',
        'subobject.id': record.id,
        'subobject.type': 'ROBOT_ACTION',
        'operationType': 'ROBOT_ACTION_MGMT',
    };

    const queryString = new URLSearchParams(`filter=${JSON.stringify(filter)}`).toString();
    const targetUrl = `/robot_action_operation_history?${queryString}`;

    return (
        <Button
            component={Link}
            to={targetUrl}
            label="History">
            <History />
        </Button>
    );
};