import React, { useCallback, useState } from 'react';
import {
    List,
    Show,
    Datagrid,
    Button,
    TextField,
    SelectInput,
    FunctionField,
    DateField,
    ShowButton,
    useUnselectAll,
    SimpleForm,
    TextInput,
    NumberInput,
    SimpleShowLayout,
    useRecordContext,
    TopToolbar,
    Confirm,
    useDataProvider,
    useNotify,
    useRefresh,
    required,
} from 'react-admin';
import { Check, HourglassEmpty } from '@mui/icons-material';

import ImageGallery from './components/ImageGallery';

import { BluehillPagination } from './bluehillPagination';

const filters = [
    <TextInput label="Robot ID" source="robotId" alwaysOn />,
    <SelectInput source="ticketStatusType" validate={required()} choices={[
        { id: 'OPEN', name: 'Open' },
        { id: 'ALL', name: 'All' },
    ]} alwaysOn />
];

const BulkResovleButton = ({ selectedIds, resource }) => {
    const dataProvider = useDataProvider();
    const notify = useNotify();
    const refresh = useRefresh();
    const unselectAll = useUnselectAll(resource);
    const [open, setOpen] = useState(false);
    const onConfirm = useCallback(() => {
        dataProvider.create(`trouble_tickets/~/operations/resolve/executions`, {
            ids: selectedIds
        }).then(() => {
            notify('Tickets resovled');
            setOpen(false);
            unselectAll();
            refresh();
        });
    }, [selectedIds]);
    return (
        <>
            <Button label="Resovle" onClick={() => setOpen(true)}><Check /></Button>
            <Confirm
                isOpen={open}
                title="Resolve"
                content="This action will resovle selected tickets. Are you sure you want to continue?"
                onConfirm={onConfirm}
                onClose={() => setOpen(false)}
            />
        </>
    );
}

const BulkActionButtons = props => (
    <>
        <BulkResovleButton {...props} />
    </>
);

export const TroubleTicketList = props => (
    <List {...props}
        perPage={50} sort={{ field: '', order: '' }}
        filters={filters} filterDefaultValues={{ ticketStatusType: 'OPEN', excludeTicketStatus: 'RESOLVED' }}
        pagination={<BluehillPagination />}>
        <Datagrid bulkActionButtons={<BulkActionButtons />}>
            <TextField source="title" style={{ wordBreak: 'break-word' }} />
            <TextField source="severity" />
            <TextField source="ticketStatus" />
            <TextField source="storeId" />
            <TextField source="robotId" />
            <DateField source="suppressionExpirationTime" label="Suppressed Before" showTime />
            <DateField source="updatedTime" showTime />
            <ShowButton />
            <ResolveButton />
            <SuppressButton />
        </Datagrid>
    </List>
);

function ResolveButton() {
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const [open, setOpen] = React.useState(false);
    function onConfirm() {
        dataProvider.create(`trouble_tickets/${record.id}/operations/resolve/executions`, {})
            .then(() => {
                refresh();
                notify('Ticket resovled');
            })
            .catch((e) => {
                notify(e.message);
            });
        setOpen(false);
    }

    return (
        <>
            {record?.ticketStatus !== 'RESOLVED'
                ? <Button onClick={() => setOpen(true)} label="Resolve">{<Check />}</Button>
                : null
            }
            <Confirm
                isOpen={open}
                title="Resolve ticket"
                content="Action is not undoable, are you sure?"
                onConfirm={onConfirm}
                onClose={() => setOpen(false)}
            />
        </>
    )
}

function SuppressButton() {
    const notify = useNotify();
    const refresh = useRefresh();
    const dataProvider = useDataProvider();
    const record = useRecordContext();
    const [open, setOpen] = React.useState(false);
    const [hours, setHours] = React.useState(0);
    function onConfirm() {
        dataProvider.create(`trouble_tickets/${record.id}/operations/suppress_alarm/executions`, {
            data: { suppressionDuration: hours * 3600000 }
        })
            .then(() => {
                refresh();
                notify('Ticket suppressed');
            })
            .catch((e) => {
                notify(e.message);
            });
        setOpen(false);
    }

    return (
        <>
            {record?.ticketStatus !== 'RESOLVED'
                ? <Button onClick={() => setOpen(true)} label="Suppress">{<HourglassEmpty />}</Button>
                : null
            }
            <Confirm
                isOpen={open}
                title="Suppress ticket"
                content={
                    <SimpleForm toolbar={null}>
                        <NumberInput
                            label="Suppress hours"
                            source="suppressionDuration"
                            onChange={(ev) => setHours(ev.target.value)}
                        />
                    </SimpleForm>
                }
                onConfirm={onConfirm}
                onClose={() => setOpen(false)}
            />
        </>
    )
}

function TroubleTicketActions(props) {
    return (
        <TopToolbar>
            <ResolveButton />
            <SuppressButton />
        </TopToolbar>
    )
}

function ImageTimestamp(item) {
    return <DateField source="createdTime" record={item} showTime />;
}

function Images(props) {
    const { images } = useRecordContext();
    
    return (
        <ImageGallery
            data={images}
            source="imageThumbnailUrl"
            subTitle={ImageTimestamp}
        />
    );
}

export const TroubleTicketShow = props => {
    return (
        <Show {...props} actions={<TroubleTicketActions />}>
            <SimpleShowLayout>
                <TextField source="title" />
                <TextField source="severity" />
                <TextField source="ticketStatus" />
                <TextField source="issueType" />
                <TextField source="ticketKey" label="Dedup Key" />
                <TextField source="storeId" />
                <TextField source="robotId" />
                <TextField source="description" />
                <DateField source="suppressionExpirationTime" label="Suppressed Before" showTime />
                <FunctionField
                    label="Escalated?"
                    render={record => `${record.escalationLevelNumber >= 1 ? true : false}`}
                />
                <FunctionField
                    label="Time To Escalate"
                    render={record => `${record.timeToEscalate / 1000 / 60} minutes`}
                />
                <FunctionField
                    label="Minimum Notification Interval"
                    render={record => `${record.minimumNotificationInterval / 1000 / 60} minutes`}
                />
                <DateField source="createdTime" showTime />
                <DateField source="updatedTime" showTime />
                <Images source="images" label="Images" src="imageThumbnailUrl" />
            </SimpleShowLayout>
        </Show>
    );
};
