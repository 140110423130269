import React, { useCallback } from 'react';
import { cloneDeep, set } from 'lodash';
import {
    Create,
    RecordContextProvider,
    SimpleForm,
    ReferenceInput,
    TextInput,
    required,
} from 'react-admin';
import { Dialog, DialogTitle, DialogContent, Menu, MenuItem } from '@mui/material';
import {
    ReactFlow,
    BaseEdge,
    Controls,
    ControlButton,
    Background,
    MarkerType,
    BezierEdge,
    useNodesState,
    useEdgesState,
    addEdge
} from '@xyflow/react';
import { SmartStepEdge } from '@tisoap/react-flow-smart-edge';
import '@xyflow/react/dist/style.css';

import AutocompleteInput from './BaseAutocompleteInput';

// function GroupNode({ data }) {
//     return (
//       <div style={{ padding: '10px', border: '1px solid #ddd', borderRadius: '5px', background: '#fff' }}>
//         <Handle type="target" position={Position.Top} />
//         <Handle type="source" position={Position.Bottom} />
//         {data.label}
//       </div>
//     );
// }

function CustomBezierEdge(props) {
    // we are using the default bezier edge when source and target ids are different
    if (props.source !== props.target) {
        return <BezierEdge {...props} />;
    }

    const { sourceX, sourceY, targetX, targetY, id, markerEnd } = props;
    const radiusY = (sourceY - targetY);
    const radiusX = 100;
    const edgePath = `M ${sourceX - 5} ${sourceY} A ${radiusX} ${radiusY} 0 1 0 ${
        targetX + 2
    } ${targetY}`;

    return <BaseEdge {...props} path={edgePath} />;
}

function makeNode(item, { isNew } = {}) {
    const id = item.id ?? Math.random().toString(36).slice(2);
    return ({
        id,
        position: {
            x: 0,
            y: 0,
            ...item.visulizationInfo,
        },
        data: {
            label: item.taskGroupKey,
        },
        style: {
            width: 'auto',
        }
    });
}

function makeEdge(item) {
    return ({
        id: `${item.from.id}-${item.to.id}`,
        source: item.from.id,
        target: item.to.id,
        type: 'customBezier',
        label: item.output,
        labelShowBg: true,
        markerEnd: {
          type: MarkerType.ArrowClosed,
          width: 20,
          height: 20
        }
    });
}

const nodeTypes = {
    // groupNode: GroupNode
};

const edgeTypes = {
    customBezier: CustomBezierEdge,
    smart: SmartStepEdge,
};

export default function FlowCanvas({ value, onChange, disabled }) {
    const [nodes, setNodes, onNodesChange] = useNodesState((value?.taskGroups ?? []).map((item) => makeNode(item)));
    const [edges, setEdges, onEdgesChange] = useEdgesState((value?.transitions ?? []).map(makeEdge));

    const [newNode, setNewNode] = React.useState(null);
    const [openingNode, setOpeningNode] = React.useState(null);
    const [editingNode, setEditingNode] = React.useState(null);

    const [edittingEdge, setEditingEdge] = React.useState(null);
    const [openingTransition, setOpeningTransition] = React.useState(null);

    const [menuAnchor, setMenuAnchor] = React.useState(null);

    const createMenuAnchor = useCallback((ev) => {
        const anchor = document.createElement('div');
        anchor.style = `
            position: absolute;
            top: ${ev.clientY}px;
            left: ${ev.clientX}px;
            width: 0;
            height: 0;
        `
        document.body.appendChild(anchor);
        setMenuAnchor(anchor);
    }, []);

    const handleNodesChange = useCallback((nds) => {
        onNodesChange(nds);
        const positions = nds.filter((event) => !event.dragging && event.type === 'position');
        if (positions.length) {
            const next = cloneDeep(value);
            positions.forEach((node) => {
                const item = next.taskGroups.find((item) => item.id === node.id);
                if (item) {
                    item.visulizationInfo = { ...node.position };
                }
            });
            onChange(next);
        }
        const removed = nds.filter((event) => event.type === 'remove');
        if (removed.length) {
            const next = cloneDeep(value);
            next.taskGroups = next.taskGroups.filter((item) => removed.every((node) => node.id !== item.id));
            next.transitions = next.transitions.filter((item) => removed.every((node) => item.from.id !== node.id && item.to.id !== node.id));
            onChange(next);
        }
    }, [value, onChange, onNodesChange]);

    const onAddNode = useCallback(() => {
        setNewNode({});
    }, []);

    const onNodeAdded = useCallback((data) => {
        const next = cloneDeep(value);
        if (!next.taskGroups) {
            next.taskGroups = [];
        }
        if (!next.transitions) {
            next.transitions = [];
        }
        data.id = Math.random().toString(36).slice(2);
        data.visulizationInfo = { x: next.taskGroups.length * 10, y: next.taskGroups.length * 10 };
        const node = makeNode(data);
        setNodes((nds) => nds.concat(node));
        next.taskGroups.push(data);
        onChange(next);
        setNewNode(null);
    }, [onChange, setNodes, value]);

    const onNodeDoubleClick = useCallback((ev, node) => {
        const item = value.taskGroups.find((item) => item.id === node.id);
        setEditingNode(cloneDeep(item));
    }, [value.taskGroups]);

    const onNodeContextMenu = useCallback((ev, node) => {
        ev.preventDefault();
        createMenuAnchor(ev);
        const item = value.taskGroups.find((item) => item.id === node.id);
        setOpeningNode(item);
    }, [createMenuAnchor, value.taskGroups]);

    const onNodeMenuClose = useCallback(() => {
        document.body.removeChild(menuAnchor);
        setMenuAnchor(null);
        setOpeningNode(null);
    }, [menuAnchor]);

    const onEditNode = useCallback(() => {
        setEditingNode(cloneDeep(openingNode));
        onNodeMenuClose();
    }, [onNodeMenuClose, openingNode]);

    const onNodeSubmit = useCallback((data) => {
        const next = cloneDeep(value);
        const index = next.taskGroups.findIndex((item) => item.id === data.id);
        next.taskGroups.splice(index, 1, data);
        onChange(next);
        const node = makeNode(data);
        setNodes((nds) => nds.map((item) => item.id === node.id ? node : item));
        setEditingNode(null);
    }, [value]);

    const onDeleteNode = useCallback(() => {
        const next = cloneDeep(value);
        next.taskGroups = next.taskGroups.filter((item) => item.id !== openingNode.id);
        next.transitions = next.transitions.filter((item) => item.from.id !== openingNode.id && item.to.id !== openingNode.id);
        onChange(next);
        setNodes((nds) => nds.filter((item) => item.id !== openingNode.id));
        setOpeningNode(null);
        setMenuAnchor(null);
    }, [value, onChange, openingNode]);

    const handleConnect = useCallback(
        (params) => {
            const next = cloneDeep(value);
            const from = next.taskGroups.find((item) => item.id === params.source);
            const to = next.taskGroups.find((item) => item.id === params.target);
            const transition = {
                from: {
                    id: from.id,
                    taskGroupKey: from.taskGroupKey,
                },
                to: {
                    id: to.id,
                    taskGroupKey: to.taskGroupKey,
                },
                output: '',
            };
            next.transitions.push(transition);
            onChange(next);

            const edge = makeEdge(transition);
            setEdges((eds) => addEdge(edge, eds));
        },
        [value, onChange, setEdges],
    );

    const handleEdgeChange = useCallback((eds) => {
        onEdgesChange(eds);
    }, [onEdgesChange]);

    const handleEdgesDelete = useCallback((eds) => {
        const next = cloneDeep(value);
        const deletingIndexes = eds.map((edge) => next.transitions.findIndex((item) => item.from.id === edge.source && item.to.id === edge.target));
        next.transitions = next.transitions.filter((item, index) => !deletingIndexes.includes(index));
        onChange(next);
    }, [value, onChange]);

    const onEdgeDoubleClick = useCallback((ev, edge) => {
        const transition = value.transitions.find((item) => item.from.id === edge.source && item.to.id === edge.target);
        setEditingEdge(cloneDeep(transition));
    }, [value]);

    const onEdgeSubmit = useCallback((data) => {
        const next = cloneDeep(value);
        const index = next.transitions.findIndex((item) => item.from.id === data.from.id && item.to.id === data.to.id);
        next.transitions.splice(index, 1, data);
        onChange(next);
        const edge = makeEdge(data);
        setEdges((eds) => eds.map((item) => item.id === edge.id ? edge : item));
        setEditingEdge(null);
    }, [value]);

    const onEdgeContextMenu = useCallback((ev, edge) => {
        ev.preventDefault();
        const transition = value.transitions.find((item) => item.from.id === edge.source && item.to.id === edge.target);
        createMenuAnchor(ev);
        setOpeningTransition(transition);
    }, [value.transitions]);

    const onTransitionMenuClose = useCallback(() => {
        document.body.removeChild(menuAnchor);
        setMenuAnchor(null);
        setOpeningTransition(null);
    }, [menuAnchor]);

    const onEditTransition = useCallback(() => {
        setEditingEdge(cloneDeep(openingTransition));
        onTransitionMenuClose();
    }, [openingTransition, onTransitionMenuClose]);

    const onDeleteTransition = useCallback(() => {
        const next = cloneDeep(value);
        const index = next.transitions.findIndex((item) => item.from.id === openingTransition.from.id && item.to.id === openingTransition.to.id);
        next.transitions.splice(index, 1);
        onChange(next);
        setEdges((eds) => eds.filter((item) => item.source !== openingTransition.from.id || item.target !== openingTransition.to.id));
        onTransitionMenuClose();
    }, [value, onChange, openingTransition, setEdges, onTransitionMenuClose]);

    const events = disabled ? {} : {
        onNodesChange: handleNodesChange,
        onNodeDoubleClick,
        onNodeContextMenu,
        onConnect: handleConnect,
        onEdgesDelete: handleEdgesDelete,
        onEdgesChange: handleEdgeChange,
        onEdgeDoubleClick,
        onEdgeContextMenu,
    };

    return (
        <>
            <ReactFlow
                fitView
                nodes={nodes}
                edges={edges}
                nodeTypes={nodeTypes}
                edgeTypes={edgeTypes}
                {...events}
            >
                <Controls showZoom={false} showInteractive={false} position="top-left">
                    {disabled ? null : (
                        <ControlButton onClick={onAddNode}>Add</ControlButton>
                    )}
                </Controls>
                <Background />
            </ReactFlow>
            <Dialog open={Boolean(newNode)} maxWidth="md" fullWidth onClose={() => setNewNode(null)}>
                <DialogTitle>Add new node</DialogTitle>
                <DialogContent>
                    <Create>
                        <SimpleForm onSubmit={onNodeAdded}>
                            <ReferenceInput sort={{ field: 'taskGroupKey', order: 'ASC' }}
                                source="taskGroupKey" reference="robot_task_group_keys" fullWidth>
                                <AutocompleteInput optionValue="taskGroupKey" optionText="taskGroupKey" validate={[required()]} label="Task Group Key" />
                            </ReferenceInput>
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
            <Dialog open={Boolean(editingNode)} maxWidth="md" fullWidth onClose={() => setEditingNode(null)}>
                <DialogTitle>Edit node</DialogTitle>
                <DialogContent>
                    <Create>
                        <SimpleForm onSubmit={onNodeSubmit} defaultValues={editingNode}>
                            <ReferenceInput sort={{ field: 'taskGroupKey', order: 'ASC' }}
                                source="taskGroupKey" reference="robot_task_group_keys" fullWidth>
                                <AutocompleteInput optionValue="taskGroupKey" optionText="taskGroupKey" validate={[required()]} label="Task Group Key"
                                    filterToQuery={searchText => ({partialTaskGroupKey: searchText})} />
                            </ReferenceInput>
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
            <Dialog open={Boolean(edittingEdge)} maxWidth="md" fullWidth onClose={() => setEditingEdge(null)}>
                <DialogTitle>Edit transition</DialogTitle>
                <DialogContent>
                    <Create>
                        <SimpleForm onSubmit={onEdgeSubmit} defaultValues={edittingEdge}>
                            <TextInput source="output" />
                        </SimpleForm>
                    </Create>
                </DialogContent>
            </Dialog>
            <Menu
                open={Boolean(openingNode)}
                anchorEl={menuAnchor}
                onClose={onNodeMenuClose}
            >
                <MenuItem onClick={onEditNode}>Edit</MenuItem>
                <MenuItem onClick={onDeleteNode}>Delete</MenuItem>
            </Menu>
            <Menu
                open={Boolean(openingTransition)}
                anchorEl={menuAnchor}
                onClose={onTransitionMenuClose}
            >
                <MenuItem onClick={onEditTransition}>Edit</MenuItem>
                <MenuItem onClick={onDeleteTransition}>Delete</MenuItem>
            </Menu>
        </>
    );
}
