import * as React from "react";
import {
    List,
    Show,
    Datagrid,
    NumberField,
    TextField,
    DateField,
    ShowButton,
    EditButton,
    DeleteButton,
    SaveButton,
    Edit,
    SimpleForm,
    BooleanInput,
    TextInput,
    NumberInput,
    SimpleShowLayout,
    Toolbar,
    ArrayField,
    ReferenceArrayField,
    ReferenceManyField,
    FunctionField,
    SingleFieldList,
    Link,
    useRecordContext,
} from 'react-admin';
import { Chip, Button, Box, Select, MenuItem, Grid, Dialog, DialogContent, DialogTitle, TableContainer, Table, TableHead, TableBody, TableRow, TableCell, Paper } from '@mui/material';
import Divider from '@mui/material/Divider';
import { BaseJsonField, BaseJsonInput } from "./components/BaseJsonComponent.js";
import { BluehillPagination } from './bluehillPagination';

const filters = [
    <TextInput source="object.id" label="Robot Configuration" alwaysOn />,
    <TextInput source="object.type" disabled alwaysOn />,
    <TextInput source="subobject.id" label="Action ID" alwaysOn />,
    <TextInput source="subobject.type" disabled alwaysOn />,
    <TextInput source="operationType" disabled alwaysOn />,
];

const RevertButton = (prop) => {
    const record = useRecordContext();
    const revertSource = record.payload[prop.fieldName];
    return (
            <Button
                color="primary"
                component={Link}
                to={{
                    pathname: `/robot_actions/${revertSource.id}/revert`,
                    state:
                        { record:
                            {
                                id: revertSource.id,
                                configuration: revertSource.configuration,
                                type: revertSource.type,
                                name: revertSource.name,
                                properties: revertSource.properties,
                                additionalProperties: revertSource.additionalProperties,
                            }
                        },
                }}>
                Revert
            </Button>
        );
}

export const ActionOperationHistoryList = props => (
    <List {...props} perPage={50} sort={{field:'', order:''}}
        filters={filters}
        filterDefaultValues={{
            'object.id': '0',
            'object.type': 'ROBOT_CONFIGURATION',
            'subobject.id': '0',
            'subobject.type': 'ROBOT_ACTION',
            'operationType': 'ROBOT_ACTION_MGMT',
        }}
        pagination={<BluehillPagination />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="operationType" style={{ wordBreak: 'break-word' }} />
            <TextField source="operationSubType" style={{ wordBreak: 'break-word' }} />
            <TextField source="object.id" label="Object ID" />
            <TextField source="object.type" label="Object Type" />
            <TextField source="subObject.id" label="Subobject ID" />
            <TextField source="subObject.type" label="Subobject Type" />
            <TextField source="operatorInfo.name" label="Operator" />
            <DateField source="createdTime" showTime />
            <ShowButton />
        </Datagrid>
    </List>
);

export const ActionOperationHistoryShow = props => {

    return (
        <Show {...props}>
            <SimpleShowLayout>
                <TextField source="operationHistoryId" />
                <TextField source="operationType" />
                <TextField source="operationSubType" />
                <TextField source="object.id" />
                <TextField source="object.type" />
                <TextField source="subObject.id" label="Subobject ID" />
                <TextField source="subObject.type" label="Subobject Type" />
                <TextField source="operatorInfo.id" />
                <TextField source="operatorInfo.name" />
                <TextField source="operatorInfo.type" />
                <DateField source="createdTime" showTime />
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <BaseJsonField source="payload.oldData" label="Old Data" reactJsonOptions={{ name: null }} />
                    </Grid>
                    <Grid item xs={6}>
                        <BaseJsonField source="payload.newData" label="New Data" reactJsonOptions={{ name: null }} />
                    </Grid>
                </Grid>
            </SimpleShowLayout>
        </Show>
    );
};