// in src/App.js
import React from "react";
import { Admin, Resource, defaultTheme, CustomRoutes } from 'react-admin';
import { Route } from 'react-router-dom';

// Bluehill data provider
import bluehillDataProvider from './bluehillDataProvider';
import { PageList, PageShow, PageEdit, PageCreate } from './pages';
import { ProgramKeyList, ProgramKeyEdit, ProgramKeyCreate } from './robotProgramKeys';
import { ProgramList, ProgramEdit, ProgramCreate, ProgramShow } from './robotPrograms';
import { RobotGroupProgramList, RobotGroupProgramEdit, RobotGroupProgramCreate, RobotGroupProgramShow } from './robotGroupPrograms';
import { TaskKeyList, TaskKeyEdit, TaskKeyCreate } from './robotTaskKeys';
import { TaskGroupKeyList, TaskGroupKeyCreate, TaskGroupKeyEdit, TaskGroupKeyShow } from './robotTaskGroupKeys';
import { TaskList, TaskEdit, TaskCreate, TaskShow } from './robotTasks';
import { TaskOperationExecute } from './robotTaskOperationExecutions';
import { TaskVariableRuleList, TaskVariableRuleEdit, TaskVariableRuleCreate, TaskVariableRuleShow } from './robotTaskVariableRules';
import { ActionList, ActionShow, ActionEdit, ActionRevert } from './robotActions';
import { ActionOperationHistoryList, ActionOperationHistoryShow } from './robotActionOperationHistory';
import { AttributeList, AttributeCreate, AttributeShow } from './robotAttributes';
import { RobotIOMetadataList, RobotIOMetadataEdit, RobotIOMetadataCreate } from './robotIOsMetadata';
import { RobotIOList, RobotIOShow, RobotIOEdit, RobotIOCreate } from './robotIOs';
import { RobotIOGroupList, RobotIOGroupEdit, RobotIOGroupCreate } from './robotIOGroups';
import { RobotIOControlDataCreate, RobotIOControlDataEdit, RobotIOControlDataList, RobotIOControlDataShow } from './robotIOControl';
import { CommonRobotIOControlDataList, CommonRobotIOControlDataShow } from './robotCommonIOControl';
import { KeypointList, KeypointShow, KeypointEdit, KeypointCreate } from './robotKeypoints';
import { OrderItemRecordList, OrderItemRecordShow } from './orderItemRecords';
import { OperationHistoryList, OperationHistoryShow } from './operationHistory';
import { StoreList, StoreShow, StoreCreate, StoreEdit } from './stores';
import { StoreSupplyItemList, StoreSupplyItemCreate } from './storeSupplyItems';
import { SupplyItemMetadataList, SupplyItemMetadataEdit, SupplyItemMetadataCreate } from './supplyItemMetadata';
import { StoreProductList, StoreProductShow, StoreProductEdit, StoreProductCreate } from './storeProducts';
import { ProductMetadataList, ProductMetadataShow, ProductMetadataCreate, ProductMetadataEdit } from "./productsMetadata";
import { OrderList, OrderShow } from './orders';
import { AttributeMetadataList, AttributeMetadataShow, AttributeMetadataCreate, AttributeMetadataEdit } from "./attributeMetadata";
import { OptionMetadataList, OptionMetadataShow, OptionMetadataCreate, OptionMetadataEdit } from "./optionMetadata";
import { ProductGroupsList, ProductGroupsShow, ProductGroupsCreate, ProductGroupsEdit } from "./productGroups";
import { ProductProgramKeyRelationList, ProductProgramKeyRelationCreate } from "./productProgramKeyRelation";
import { TroubleTicketList, TroubleTicketShow } from "./troubleTickets";
import { RobotObjectList, RobotObjectShow, RobotObjectEdit } from "./robotObjects"
import { RobotOrderTestList, RobotOrderTestCreate } from "./robotOrderTests"
import { RobotOrderTestCaseShow } from './robotOrderTestCase'
import { CustomerList, CustomerShow } from "./customers";
import { CouponTemplateList } from "./couponTemplates";
import { IssuesMetadataCreate, IssuesMetadataEdit, IssuesMetadataList, IssuesMetadataShow } from "./issueMetadata";
import { RobotList, RobotShow, RobotEdit, RobotCreate } from "./robots";
import { RobotWatchObjectHistoryList } from './robotWatchObjectHistory';
import { RobotImportedDataBatchList, RobotImportedDataBatchCreate } from "./robotImportedDataBatches";
import { RobotExportedDataBatchList, RobotExportedDataBatchShow, RobotExportedDataBatchCreate } from "./robotExportedDataBatches";

// Keycloak auth provider
import { ReactKeycloakProvider } from "@react-keycloak/web";
import { keycloak, keycloakConfig } from './keycloak.js';
import KeycloakAuthProvider from './keycloakAuthProvider';


import { BluehillLayout } from './layout';
import { store } from './bluehillReducer';

import './App.css';


import { Provider } from 'react-redux';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

const theme = {
  ...defaultTheme,
  components: {
    ...defaultTheme.components,
    RaAutocompleteInput: {
      styleOverrides: {
        root: {
          minWidth: 256,
        }
      }
    },
    RaSelectInput: {
      styleOverrides: {
        root: {
          minWidth: 256,
        }
      }
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
};

const Content = () => {
  const keycloakAuthProvider = KeycloakAuthProvider();
  return (
    <Provider store={store}>
      <Admin layout={BluehillLayout} theme={theme} dataProvider={bluehillDataProvider} authProvider={keycloakAuthProvider} error={'div'}>
        <Resource name="pages" options={{ label: 'Tutorial' }}
          list={PageList} show={PageShow} edit={PageEdit} create={PageCreate} />
        <Resource name="robot_program_keys"
          list={ProgramKeyList} edit={ProgramKeyEdit} create={ProgramKeyCreate} />
        <Resource name="robot_programs"
          list={ProgramList} edit={ProgramEdit} create={ProgramCreate} show={ProgramShow} />
        <Resource name="robot_group_programs"
          list={RobotGroupProgramList} edit={RobotGroupProgramEdit} create={RobotGroupProgramCreate} show={RobotGroupProgramShow} />
        <Resource name="robot_task_keys"
          list={TaskKeyList} edit={TaskKeyEdit} create={TaskKeyCreate} />
        <Resource name="robot_task_group_keys"
          list={TaskGroupKeyList} edit={TaskGroupKeyEdit} create={TaskGroupKeyCreate} show={TaskGroupKeyShow} />
        <Resource name="robot_tasks"
          list={TaskList} edit={TaskEdit} create={TaskCreate} show={TaskShow} />
        <Resource name="robot_task_operation_executions"
          edit={TaskOperationExecute} />
        <Resource name="robot_task_variable_rules"
          list={TaskVariableRuleList} create={TaskVariableRuleCreate} edit={TaskVariableRuleEdit} show={TaskVariableRuleShow} />
        <Resource name="robot_actions"
          list={ActionList} show={ActionShow} edit={ActionEdit} />
        <Resource name="robot_attributes"
          list={AttributeList} create={AttributeCreate} show={AttributeShow} />
        <Resource name="robot_ios_metadata"
          list={RobotIOMetadataList} create={RobotIOMetadataCreate} edit={RobotIOMetadataEdit} />
        <Resource name="robot_ios"
          list={RobotIOList} show={RobotIOShow} edit={RobotIOEdit} create={RobotIOCreate} />
        <Resource name="robot_ios_control_data"
          list={RobotIOControlDataList} show={RobotIOControlDataShow} edit={RobotIOControlDataEdit} create={RobotIOControlDataCreate} />
        <Resource name="robot_common_ios_control_data"
          list={CommonRobotIOControlDataList} show={CommonRobotIOControlDataShow} />
        <Resource name="robot_io_groups"
          list={RobotIOGroupList} edit={RobotIOGroupEdit} create={RobotIOGroupCreate} />
        <Resource name="robot_keypoints" 
          list={KeypointList} show={KeypointShow} edit={KeypointEdit} create={KeypointCreate} />
        <Resource name="order_items" />
        <Resource name="order_item_records"
          list={OrderItemRecordList} show={OrderItemRecordShow} />
        <Resource name="orders_waiting_info" />
        <Resource name="operation_history"
          list={OperationHistoryList} show={OperationHistoryShow} />
        <Resource name="operation_history_summary" />
        <Resource name="robot_action_operation_history"
          list={ActionOperationHistoryList} show={ActionOperationHistoryShow} />
        <Resource name="store_supply_items"
          list={StoreSupplyItemList} create={StoreSupplyItemCreate} />
        <Resource name="supply_items_metadata"
          list={SupplyItemMetadataList} edit={SupplyItemMetadataEdit} create={SupplyItemMetadataCreate} />
        <Resource name="stores" list={StoreList} show={StoreShow} create={StoreCreate} edit={StoreEdit} />
        <Resource name="stores_weekly_schedules" />
        <Resource name="orders" list={OrderList} show={OrderShow} />
        <Resource name="orders_email_receipt_info" />
        <Resource name="products" />
        <Resource name="product_attributes_metadata"
          list={AttributeMetadataList} show={AttributeMetadataShow} edit={AttributeMetadataEdit} create={AttributeMetadataCreate} />
        <Resource name="product_attributes" />
        <Resource name="product_options_metadata"
          list={OptionMetadataList} show={OptionMetadataShow} edit={OptionMetadataEdit} create={OptionMetadataCreate} />
        <Resource name="product_options" />
        <Resource name="store_products_clone_executions" />
        <Resource name="store_products"
          list={StoreProductList} show={StoreProductShow} edit={StoreProductEdit} create={StoreProductCreate} />
        <Resource name="product_metadata"
          list={ProductMetadataList} show={ProductMetadataShow} create={ProductMetadataCreate} edit={ProductMetadataEdit} />
        <Resource name="product_groups"
          list={ProductGroupsList} show={ProductGroupsShow} edit={ProductGroupsEdit} create={ProductGroupsCreate} />
        <Resource name="product_program_key_relations"
          list={ProductProgramKeyRelationList} create={ProductProgramKeyRelationCreate} />
        <Resource name="robots"
          list={RobotList} show={RobotShow} edit={RobotEdit} create={RobotCreate} />
        <Resource name="display_config" />
        <Resource name="robot_watch_object_types" />
        <Resource name="robot_watch_object_history" list={RobotWatchObjectHistoryList} />
        <Resource name="trouble_tickets"
          list={TroubleTicketList} show={TroubleTicketShow} />
        <Resource name="countries" />
        <Resource name="states" />
        <Resource name="robot_objects"
          list={RobotObjectList} show={RobotObjectShow} edit={RobotObjectEdit} />
        <Resource name="robot_order_test_suites"
          list={RobotOrderTestList} create={RobotOrderTestCreate} />
        <Resource name="robot_order_test_cases" />
        <Resource name="robot_order_test_case"
          show={RobotOrderTestCaseShow} />
        <Resource name="issues_metadata"
          list={IssuesMetadataList} show={IssuesMetadataShow} edit={IssuesMetadataEdit} create={IssuesMetadataCreate} />
        <Resource name="robot_imported_data_batches"
          list={RobotImportedDataBatchList} create={RobotImportedDataBatchCreate} />
        <Resource name="robot_exported_data_batches"
          list={RobotExportedDataBatchList} show={RobotExportedDataBatchShow} create={RobotExportedDataBatchCreate} />
        <Resource name="customers" list={CustomerList} show={CustomerShow} />
        <Resource name="customer_reward_point_history" />
        <Resource name="coupon_templates" list={CouponTemplateList} />
      </Admin>
    </Provider>
  );
};


// we have a feature to completely switch off the authorization process through env variable on backend
const App = () => {
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      LoadingComponent={<div></div>}
      initOptions={keycloakConfig.initOptions}>
      <React.Fragment>
        <Content />
      </React.Fragment>
    </ReactKeycloakProvider>
  );
};



export default App;
