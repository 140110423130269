import React, { useCallback, useMemo } from "react";
import {
  AutocompleteArrayInput, useChoicesContext,
} from 'react-admin';


const BaseAutocompleteArrayInput = ({ queryFields = ['id', 'name'], shouldRenderSuggestions, children, ...props }) => {
  const { filterToQuery, optionValue } = props;
  const { allChoices, source, setFilters } = useChoicesContext();
  const matchSuggestion = useCallback((value, choice) => {
    return value
      ? queryFields.some((field) => Boolean(choice && choice[field] && `${choice[field]}`.toLowerCase().includes(value.toLowerCase()))) : true;
  }, [queryFields]);

  const renderSuggestions = searchText => true;

  const setFilter = useCallback((v) => {
    if (typeof filterToQuery === 'function') {
      setFilters(filterToQuery(v));
      return;
    }
  }, [filterToQuery, setFilters]);

  const filteredChoices = useMemo(() => {
    if (optionValue) {
      return allChoices?.filter(e => e[optionValue] != null);
    }
    return allChoices;
  }, [allChoices, optionValue]);

  return (
    <AutocompleteArrayInput 
      choices={filteredChoices}
      source={source}
      componentsProps={{ popper: { style: { width: 'fit-content' } } }}
      matchSuggestion={matchSuggestion}
      shouldRenderSuggestions={shouldRenderSuggestions ?? renderSuggestions}
      noOptionsText="No item matched"
      setFilter={setFilter}
      {...props} 
    >
      {children}
    </AutocompleteArrayInput>
);
}

export default BaseAutocompleteArrayInput;
