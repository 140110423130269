import React, { useCallback, useState } from 'react';
import { Typography } from '@mui/material';
import { useInput, useRecordContext, TextInput } from 'react-admin';
import ReactJson from 'react-json-view';

const getJsonValue = (value) => {
    if (typeof value === 'object' && value !== null) {
        return JSON.stringify(value, null, 2); // Pretty-print JSON
    } else if (value === null) {
        return "";
    }
    return value; // Return the raw value if it's not an object
}

export const BaseJsonField = ({ source, label, reactJsonOptions }) => {
    const record = useRecordContext();
    const value = source
        .split('.')
        .reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : null), record);

    if (!value) {
        return <span>No data available</span>; // Handle cases where the field doesn't exist
    }

    return (
        <>
            {label && (
                <Typography variant="label" gutterBottom>
                    {label}
                </Typography>
            )}
            <ReactJson
                src={value}
                name={reactJsonOptions.name}
                {...reactJsonOptions}
            />
        </>
    );
};

export const BaseJsonInput = (props) => {
    return (
        <>
            <TextInput
                {...props}
                validate={(value) => {
                    try {
                        JSON.parse(getJsonValue(value));
                        return null;
                    } catch (e) {
                        console.log('Failed to parse JSON', e);
                        return 'Invalid JSON';
                    }
                }}
                format={getJsonValue} // gets called twice on each update, first as string then as object
                parse={(value) => {
                    try {
                        // Convert JSON string back to an object for submission
                        return JSON.parse(value);
                    } catch (e) {
                        console.error('Failed to parse JSON:', e);
                        return value; // Return the raw value if parsing fails
                    }
                }}
            />
        </>
    );
};