import * as React from "react";
import { useState } from 'react';
import {
    List,
    Show,
    Datagrid,
    NumberField,
    TextField,
    DateField,
    ShowButton,
    EditButton,
    DeleteButton,
    SaveButton,
    Edit,
    SimpleForm,
    BooleanInput,
    TextInput,
    NumberInput,
    SimpleShowLayout,
    Toolbar,
} from 'react-admin';
import { useLocation } from 'react-router-dom';
import { BaseJsonField } from "./components/BaseJsonComponent.js";
import { ShowActionHistoryButton } from "./components/RobotActionButtons.js";
import { BluehillPagination } from './bluehillPagination';
import { JsonEditorInput } from "./components/JsonEditorInput.js";


const filters = [
    <TextInput label="Configuration" source="configuration" alwaysOn />,
    <TextInput label="Action name" source="partialName" alwaysOn />,
    <BooleanInput label="New Action API" source="useConfigurationQuery" alwaysOn />,
];

export const ActionList = props => (
    <List {...props} perPage={50} sort={{field:'', order:''}}
        filters={filters} filterDefaultValues={{ configuration:0, useConfigurationQuery: true }}
        pagination={<BluehillPagination />}>
        <Datagrid bulkActionButtons={false}>
            <TextField source="id" />
            <NumberField source="configuration" />
            <TextField source="name" style={{ wordBreak: 'break-word' }} />
            <ShowButton />
            <ShowActionHistoryButton />
            <EditButton />
        </Datagrid>
    </List>
);

export const ActionCreate = props => (
    <Edit {...props}>
        <SimpleForm>
            <NumberInput source="configuration" />
            <TextInput source="name" />
        </SimpleForm>
    </Edit>
);

export const ActionEdit = props => {
    console.log('ActionEdit');
    return (
        <Edit {...props}>
            <SimpleForm
                toolbar={<CustomToolbar deleteButton={false}/>}>
                <TextInput source="id" disabled />
                <TextInput source="configuration" disabled />
                <TextInput source="type" disabled />
                <TextInput source="name" fullWidth />
                <JsonEditorInput label="Properties" source="properties" jsonString={false} height="30vh" />
                <JsonEditorInput label="Additional Properties" source="additionalProperties" jsonString={false} height="20vh" />
            </SimpleForm>
        </Edit>
    );
};

export const ActionRevert = props => {
    console.log('ActionRevert');
    const location = useLocation();
    const initialRecord = location.state?.record;
    console.log('initialRecord', initialRecord);

    return (
        <Edit {...props}>
            <SimpleForm
                toolbar={<CustomToolbar deleteButton={false}/>}
                initialValues={initialRecord}>
                <TextInput source="id" disabled />
                <TextInput source="configuration" disabled />
                <TextInput source="type" disabled />
                <TextInput source="name" fullWidth />
                <JsonEditorInput label="Properties" source="properties" jsonString={false} height="30vh" />
                <JsonEditorInput label="Additional Properties" source="additionalProperties" jsonString={false} height="20vh" />
            </SimpleForm>
        </Edit>
    );
};

export const ActionShow = props => (
    <Show {...props}>
        <SimpleShowLayout>
            <NumberField source="id" />
            <NumberField source="configuration" />
            <TextField source="name" />
            <NumberField source="type" />
            <BaseJsonField
                source="properties"
                reactJsonOptions={{
                  // Props passed to react-json-view
                  name: null,
                  collapsed: false,
                  enableClipboard: true,
                  displayDataTypes: false,
                }}
              />
            <BaseJsonField
                source="additionalProperties"
                reactJsonOptions={{
                  // Props passed to react-json-view
                  name: null,
                  collapsed: false,
                  enableClipboard: true,
                  displayDataTypes: false,
                }}
              />
            <DateField source="lastModifiedTime" showTime />
        </SimpleShowLayout>
    </Show>
);

const CustomToolbar = ({ deleteButton, ...props }) => (
    <Toolbar {...props} style={{ "justifyContent": "space-between" }}>
        <SaveButton />
        {deleteButton && <DeleteButton />}
    </Toolbar>
);