import React, { useEffect, useState, useMemo, useCallback } from "react";
import {
    List,
    Datagrid,
    TextField,
    TextInput,
    NumberField,
    NumberInput,
    BooleanField,
    ReferenceManyField,
    EditButton,
    ShowButton,
    Show,
    ArrayInput,
    useNotify,
    TabbedShowLayout,
    DeleteButton,
    Tab,
    Toolbar,
    SaveButton,
    Edit,
    SimpleFormIterator,
    ImageField,
    TabbedForm,
    SimpleForm,
    ReferenceInput,
    SelectInput,
    BooleanInput,
    Create,
    FormDataConsumer,
    useEditController,
    useDataProvider,
    AutocompleteInput,
    required,
    useInput,
    useRecordContext,
    Button,
    FunctionField,
    DateField,
} from 'react-admin';
import { styled } from '@mui/system';
import { Link, Grid } from '@mui/material';
import { useLocation } from 'react-router-dom';
import { useFormContext } from 'react-hook-form';
import { makeStyles } from '@mui/styles';
import { ImageUpload } from "./components/ImageUpload";
import ReactPhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { setSubmissionErrors } from 'ra-core';
import { JsonField } from "react-admin-json-view";

function shouldRenderSuggestions(value) {
    return true;
}

const storeVisibilityTypeOptions = [
    { id: 'PUBLIC', name: 'PUBLIC' },
    { id: 'PRIVATE', name: 'PRIVATE' },
    { id: 'TEST', name: 'TEST' },
    { id: 'ARCHIVED', name: 'ARCHIVED' },
]

const filters = [
    <SelectInput source="visibilityType"
        choices={storeVisibilityTypeOptions} alwaysOn />
];

const regionCodeOptions = [
    { id: 'US-WA-Seattle', name: 'US-WA-Seattle' },
    { id: 'US-OR-Portland', name: 'US-OR-Portland' },
    { id: 'US-CA-San_Francisco', name: 'US-CA-San_Francisco' },
    { id: 'CA-BC-Vancouver', name: 'CA-BC-Vancouver' },
    { id: 'JP-Tokyo', name: 'JP-Tokyo' },
    { id: 'JP-Hokkaido', name: 'JP-Hokkaido' },
    { id: 'JP-Osaka', name: 'JP-Osaka' },
    { id: 'JP-Kyoto', name: 'JP-Kyoto' },
];

const storeModeOptions = [
    { id: 'UNSTAFFED', name: 'UNSTAFFED' },
    { id: 'QUICK_RESPOND_MAINTENANCE', name: 'QUICK_RESPOND_MAINTENANCE' },
    { id: 'SLOW_RESPOND_MAINTENANCE', name: 'SLOW_RESPOND_MAINTENANCE' }
];

const useShowStyle = makeStyles({
    referenceFieldBlock: {
        border: '1px solid #ddd',
        padding: '0 1em 1em 1em',
        marginBottom: '1em',

        '& > div > label': {
            fontSize: '1.2em',
        }
    }
});

const countryRenderer = country => country && `(${country.countryCode}) ${country.countryName}`;

const stateRenderer = state => state && `(${state.stateCode}) ${state.stateName}`;

const reasonRenderer = (reasonStatusInformation) => {
    const reasonTitle = reasonStatusInformation?.reasonTitle;
    const reasonDescription = reasonStatusInformation?.reasonDescription;
    return reasonTitle ? (reasonDescription ? `${reasonTitle}: ${reasonDescription}` : reasonTitle) : '';
}

const DisabledReasonField = ({label}) => {
    const record = useRecordContext();
    if (!record) return null;
    return <FunctionField label={label}
        color={"red"}
        render={record => reasonRenderer(record?.resourceStatusInformation)} />
}

const CountriesDataContext = React.createContext([]);

function useCountriesData() {
    return React.useContext(CountriesDataContext);
}

const CountriesDataProvider = (props) => {
    const [countries, setCountries] = useState([]);
    const dataProvider = useDataProvider();
    useEffect(() => {
        dataProvider.getList(`countries`, { sort: {}, filter: {} }).then(({ data }) => {
            setCountries(data);
        });
    }, [dataProvider]);

    return (
        <CountriesDataContext.Provider value={countries}>
            {props.children}
        </CountriesDataContext.Provider>
    );
}

const PhoneInput = (props) => {
    const { id, field, fieldState } = useInput(props);
    const { getValues } = useFormContext();

    const shortCountryName = getValues('shortCountryName');

    const countries = useCountriesData();
    if (!countries.length) {
        return 'Loading...';
    }
    const countryNames = countries.map(country => country.countryCode.toLowerCase());

    return (
        <div className="MuiFormControl-marginDense">
            <ReactPhoneInput
                label={props.label}
                {...field}
                value={field.value}
                onChange={(next) => field.onChange(next)}
                disabled={props.disabled || !shortCountryName}
                country={shortCountryName?.toLowerCase()}
                onlyCountries={countryNames}
                disableDropdown
                disableCountryCode
                disableCountryGuess
                placeholder="Domestic Number"
            />
            <p className="MuiFormHelperText-root MuiFormHelperText-contained MuiFormHelperText-filled MuiFormHelperText-marginDense" id={`${id}-helper-text`}>
                <span>{fieldState.error}​</span>
            </p>
        </div>
    );
};

function stateMatcher(value, option) {
    return option.stateCode.toLowerCase().includes(value.toLowerCase()) || option.stateName.toLowerCase().includes(value.toLowerCase());
}

const StateInput = (props) => {
    const { id } = useInput(props);
    const { getValues } = useFormContext();

    const shortCountryName = getValues('shortCountryName');

    const [stateChoices, setStateChoices] = useState([]);

    const dataProvider = useDataProvider();

    useEffect(() => {
        if (shortCountryName) {
            dataProvider.getList(`countries/${shortCountryName}/states`, { sort: {}, filter: {} }).then(({ data }) => {
                setStateChoices(data);
            });
        }
    }, [dataProvider, shortCountryName]);
    return (
        <AutocompleteInput
            label="State"
            optionText={stateRenderer}
            source={id}
            choices={stateChoices}
            shouldRenderSuggestions={shouldRenderSuggestions}
            matchSuggestion={stateMatcher}
        />
    )
}

export const StoreList = props => (
    <List {...props} syncWithLocation sort={{ field: '', order: '' }}
        filters={filters}
        filterDefaultValues={{}} pagination={false} >
        <Datagrid bulkActionButtons={false}>
            <TextField label="Store ID" source="storeId" />
            <TextField label="Store Name" source="storeName" />
            <TextField label="Mode" source="mode"/>
            <TextField label="Country" source="shortCountryName" />
            <TextField label="State" source="shortStateName" />
            <TextField label="City" source="cityName" />
            <TextField label="National Phone Number" source="nationalPhoneNumber" />
            <TextField label="Sales Tax Rate" source="paymentInfo.salesTaxRate" />
            <TextField source="visibilityType" looseValue={true} />
            <FunctionField label="Notes"
                render={record => {
                    if (!record.notes) {
                        return null;
                    }
                    else if (record.notes?.length <= 50) {
                        return record.notes;
                    }
                    return record.notes?.substring(0, 50) + "...";
                }} />
            <ShowButton />
            <EditButton />
        </Datagrid>
    </List>
);

function ShowTitle(props) {
    const record = useRecordContext();
    return (
        <>
            <span>Store: </span>
            <span>{record ? record.storeName : null}</span>
        </>
    );
}

function LabelLink(props) {
    const record = useRecordContext();
    const filter = (props.recordValueKeys ?? []).reduce((result, key) => {
        let sourceKey = key;
        let targetKey = key;
        let isArray = false;
        if (typeof key === 'object') {
            sourceKey = key.source;
            targetKey = key.target;
            if (key.isArray) {
                isArray = true;
            }
        }
        if (isArray) {
            return ({ ...result, [targetKey]: [record[sourceKey]] });
        }
        return ({ ...result, [targetKey]: record[sourceKey] });
    }, props.filter ?? {})
    const filterParams = `?filter=${JSON.stringify(filter)}`;
    return (
        <Link href={`#/${props.path}${filterParams}`}>{props.children ?? record[props.source]}</Link>
    );
}

export const StoreShow = props => {
    const styles = useShowStyle();
    return (
        <Show {...props} title={<ShowTitle />}>
            <TabbedShowLayout>
                <Tab label="summary">
                    <TextField label="Store Name" source="storeName" />
                    <TextField label="Short Store Name" source="shortStoreName" />
                    <TextField label="Mode" source="mode"/>
                    <TextField label="Address Line 1" source="addressLine1" />
                    <TextField label="Address Line 2" source="addressLine2" />
                    <TextField label="City" source="cityName" />
                    <TextField label="State" source="stateName" />
                    <TextField label="Country" source="countryName" />
                    <TextField label="Zip Code" source="zipCode" />
                    <TextField label="Time Zone" source="timeZone" />
                    <TextField label="Primary Language" source="primaryLanguage" />
                    <NumberField label="Latitude" source="latitude" />
                    <NumberField label="Longitude" source="longitude" />
                    <TextField label="National Phone Number" source="nationalPhoneNumber" />
                    <TextField label="Sales Tax Rate" source="paymentInfo.salesTaxRate" />
                    <TextField label="Currency" source="paymentInfo.currencyInfo.currencyCode" />
                    <TextField label="Visibility Type" source="visibilityType" />
                    <TextField label="Notes" source="notes" />
                    <BooleanField label="Send Daily Discord Summary" source="dailySummaryEnabled" />
                    <ImageField source="primaryImageUrl" label="Primary Image" />
                    <ImageField source="primaryImageThumbnailUrl" label="primary Image Thumbnail" />
                </Tab>
                <Tab label="Disablements" path="disablements">
                    <ReferenceManyField
                        label={<LabelLink path="store_products" filter={{ enabled: false }} recordValueKeys={['storeId']}>Disabled Products</LabelLink>}
                        reference="products"
                        target="storeId"
                        filter={{ enabled: false }}
                        pagination={false}
                        className={styles.referenceFieldBlock}
                    >
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="productName" />
                            <BooleanField source="enabled" />
                            <DisabledReasonField label="Disabled reason" />
                            <DateField label="Disabled time" source="resourceStatusInformation.updatedTime" showTime />
                            <TextField source="resourceStatusInformation.operatorInfo.name" label="Disabled by" />
                        </Datagrid>
                    </ReferenceManyField>
                    <ReferenceManyField
                        label={<LabelLink path="store_products" filter={{ inStock: false }} recordValueKeys={['storeId']}>Out of Stock Products</LabelLink>}
                        reference="products"
                        target="storeId"
                        filter={{ inStock: false }}
                        pagination={false}
                        className={styles.referenceFieldBlock}
                    >
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="productName" />
                            <BooleanField source="inStock" />
                            <DisabledReasonField label="Disabled reason" />
                            <DateField label="Disabled time" source="resourceStatusInformation.updatedTime" showTime />
                            <TextField source="resourceStatusInformation.operatorInfo.name" label="Disabled by" />
                        </Datagrid>
                    </ReferenceManyField>
                    <ReferenceManyField
                        label={<LabelLink path="store_supply_items" filter={{ enabled: false }} recordValueKeys={['storeId']}>Disabled Supply Items</LabelLink>}
                        reference="store_supply_items"
                        target="storeId"
                        filter={{ enabled: false }}
                        pagination={false}
                        className={styles.referenceFieldBlock}
                    >
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="supplyItemName" />
                            <BooleanField source="enabled" />
                            <DisabledReasonField label="Disabled reason" />
                            <DateField label="Disabled time" source="resourceStatusInformation.updatedTime" showTime />
                            <TextField source="resourceStatusInformation.operatorInfo.name" label="Disabled by" />
                        </Datagrid>
                    </ReferenceManyField>

                    <ReferenceManyField
                        label="Disabled Robot IOs"
                        reference="robots"
                        target="storeIds"
                        pagination={false}
                        sort={{ field: 'robotId', order: 'ASC' }}
                        className={styles.referenceFieldBlock}
                    >
                        <Datagrid bulkActionButtons={false}>
                            <LabelLink label="Robot" path="robot_ios" filter={{ enabled: false }} recordValueKeys={[{ source: 'configuration', target: 'robotId', isArray: true }]} source="id" />
                            <ReferenceManyField label="IOs" reference="robot_ios" target="robotId" filter={{ enabled: false }} pagination={false}>
                                <Datagrid bulkActionButtons={false}>
                                    <TextField source="ioName" />
                                    <TextField source="ioControlKey" />
                                    <BooleanField source="enabled" />
                                    <DisabledReasonField label="Disabled reason" />
                                    <DateField label="Disabled time" source="resourceStatusInformation.updatedTime" showTime />
                                    <TextField source="resourceStatusInformation.operatorInfo.name" label="Disabled by" />
                                </Datagrid>
                            </ReferenceManyField>
                        </Datagrid>
                    </ReferenceManyField>
                    <ReferenceManyField
                        label="Disabled Robot Programs"
                        reference="robots"
                        target="storeIds"
                        pagination={false}
                        sort={{ field: 'robotId', order: 'ASC' }}
                        className={styles.referenceFieldBlock}
                    >
                        <Datagrid bulkActionButtons={false}>
                            <LabelLink label="Robot" path="robot_programs" filter={{ enabled: false }} recordValueKeys={['configuration']} source="id" />
                            <ReferenceManyField label="Programs" reference="robot_programs" target="configuration" filter={{ enabled: false }} pagination={false}>
                                <Datagrid bulkActionButtons={false}>
                                    <TextField source="name" />
                                    <BooleanField source="enabled" />
                                    <DisabledReasonField label="Disabled reason" />
                                    <DateField label="Disabled time" source="resourceStatusInformation.updatedTime" showTime />
                                    <TextField source="resourceStatusInformation.operatorInfo.name" label="Disabled by" />
                                </Datagrid>
                            </ReferenceManyField>
                        </Datagrid>
                    </ReferenceManyField>

                    <ReferenceManyField
                        label="Disabled Robot Attributes"
                        reference="robots"
                        target="storeIds"
                        pagination={false}
                        sort={{ field: 'robotId', order: 'ASC' }}
                        className={styles.referenceFieldBlock}
                    >
                        <Datagrid bulkActionButtons={false}>
                            <LabelLink label="Robot" path="robot_attributes" filter={{ isEnabled: false }} recordValueKeys={['configuration']} source="id" />
                            <ReferenceManyField label="Attributes" reference="robot_attributes" target="configuration" filter={{ isEnabled: false }} pagination={false}>
                                <Datagrid bulkActionButtons={false}>
                                    <TextField source="name" />
                                    <BooleanField source="enabled" />
                                </Datagrid>
                            </ReferenceManyField>
                        </Datagrid>
                    </ReferenceManyField>

                    <ReferenceManyField
                        label="Disabled Product Mappings"
                        reference="robots"
                        target="storeIds"
                        pagination={false}
                        sort={{ field: 'robotId', order: 'ASC' }}
                        className={styles.referenceFieldBlock}
                    >
                        <Datagrid bulkActionButtons={false}>
                            <LabelLink label="Robot" path="product_program_key_relations" filter={{ enabled: false }} recordValueKeys={['storeId', { source: 'configuration', target: 'configurations', isArray: true }]} source="id" />
                            <ReferenceManyField label="Product Mapping" reference="product_program_key_relations" target="configurations" filter={{ enabled: false }} pagination={false}>
                                <Datagrid bulkActionButtons={false}>
                                    <TextField source="programKey" />
                                    <BooleanField source="enabled" />
                                </Datagrid>
                            </ReferenceManyField>
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

function timeZoneMatcher(value, option) {
    return option.name.toLowerCase().includes(value.toLowerCase()) || option.id.toLowerCase().includes(value.toLowerCase());
}

function StoreInputs() {
    const timeZones = useMemo(() => {
        return Intl.supportedValuesOf('timeZone').map(item => ({ id: item, name: item }));
    }, []);
    const languageChoices = [
        { id: 'en', name: 'en' },
        { id: 'ja', name: 'ja' },
    ];

    return (
        <>
            <TextInput label="Store Name" source="storeName" />
            <TextInput label="Short Store Name" source="shortStoreName" validate={[required()]} />
            <TextInput label="Address Line 1" source="addressLine1" validate={[required()]} />
            <TextInput label="Address Line 2" source="addressLine2" />
            <TextInput label="City Name" source="cityName" validate={[required()]} />

            <SelectInput label="Mode" source="mode" choices={storeModeOptions} />
            <ReferenceInput sort={{ field: '', order: 'ASC' }}
                label="Country" source="shortCountryName" reference="countries">
                <SelectInput optionText={countryRenderer} validate={[required()]} />
            </ReferenceInput>
            <StateInput label="State" source="shortStateName" />
            <SelectInput label="Region Code" source="regionCode" choices={regionCodeOptions} />
            <TextInput label="Zip Code" source="zipCode" validate={[required()]} />
            <SelectInput label="Primary Language" source="primaryLanguage" choices={languageChoices} />
            <NumberInput label="Latitude" source="latitude" />
            <NumberInput label="Longitude" source="longitude" />
            <AutocompleteInput
                label="Timezone"
                source="timeZone"
                choices={timeZones}
                optionText="name"
                matchSuggestion={timeZoneMatcher}
                shouldRenderSuggestions={shouldRenderSuggestions}
                validate={[required()]}
            />
            <CountriesDataProvider>
                <PhoneInput label="National Phone Number" source="nationalPhoneNumber" />
            </CountriesDataProvider>
            <NumberInput label="Sales Tax Rate" source="paymentInfo.salesTaxRate" min={0} max={1} defaultValue={0} validate={[required()]} />
            <SelectInput source="visibilityType" choices={storeVisibilityTypeOptions} />
            <BooleanInput label="Is scheduling order feature enabled at this location?"
                source="orderSchedulingEnabled" defaultValue={false} />
            <TextInput label="Notes" source="notes" multiline sx={{ alignSelf: 'stretch' }} />
            <BooleanInput label="Send Daily Discord Summary" source="dailySummaryEnabled" />
            <ImageField source="primaryImageUrl" label="Current primary image" />
            <ImageField source="primaryImageThumbnailUrl" label="Current primary image thumbnail" />
        </>
    );
}

export const StoreCreate = props => {
    const [uploadMode, setUploadMode] = React.useState(true);
    const [imageUploaded, setImageUploaded] = React.useState(false);

    return (<Create {...props}>
        <SimpleForm
            toolbar={<CustomToolbar imageUploaded={imageUploaded} uploadMode={uploadMode} deleteButton={false} />}
            sanitizeEmptyValues
        >
            <NumberInput label="Store ID" source="storeId" />
            <StoreInputs />
            <ImageUpload imageUploaded={imageUploaded} uploadMode={uploadMode}
                setImageUploaded={setImageUploaded} setUploadMode={setUploadMode}
                imageSource="storeImageSource" imageFile="storeImageFile"
                primaryImageUrl="primaryImageUrl" primaryImageThumbnailUrl="primaryImageThumbnailUrl"
            />
        </SimpleForm>
    </Create>);
}

const SimpleFormIteratorRoot = styled(SimpleFormIterator)({
    '& .RaSimpleFormIterator-form': {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        '& > .MuiFormControl-root': {
            width: '6em'
        }
    }
});

const SimpleFormIteratorWeek = styled(SimpleFormIterator)({
    '& .RaSimpleFormIterator-form': {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    '& > .MuiFormGroup-root': {
        alignItems: 'flex-start',
    },
})


const useSchedules = (id) => {
    const dataProvider = useDataProvider();
    const [schedules, setSchedules] = useState(null);

    useEffect(() => {
        dataProvider.getList(`stores/${id}/weekly_schedules`, {
            pagination: {},
            sort: {},
            filter: {},
        })
            .then(({ data }) => {
                setSchedules(data);
            })
            .catch(err => console.error(err));
    }, [id]);

    return { schedules, setSchedules };
}

const SchedulesForm = props => {
    const { getValues } = useFormContext();
    const id = getValues('id');
    const { schedules } = useSchedules(id);
    return (
        schedules ?
            <ArrayInput source="schedules" defaultValue={schedules}>
                <SimpleFormIteratorWeek
                    disableReordering
                    disableAdd
                    disableClear
                    disableRemove
                    getItemLabel={(index) => schedules && schedules[index] && schedules[index].dayOfWeek}
                >
                    <FormDataConsumer>
                        {({
                            formData,
                            scopedFormData,
                            getSource,
                        }) => (
                            <>
                                <BooleanInput label="Open All Day" source={getSource('openAllDay')} />
                                {scopedFormData && !scopedFormData.openAllDay
                                    ? (
                                        <ArrayInput label="" source={getSource('openTimes')} defaultValue={scopedFormData.openTimes}>
                                            <SimpleFormIteratorRoot
                                                getItemLabel={() => null}
                                                fullWidth
                                            >
                                                <FormDataConsumer>
                                                    {(innerProps) => (
                                                        <>
                                                            <NumberInput label="Start Hour" source={innerProps.getSource('startHour')} min={0} max={23} />
                                                            <span>:</span>
                                                            <NumberInput label="Start Minute" source={innerProps.getSource('startMinute')} min={0} max={59} />
                                                            <span>~</span>
                                                            <NumberInput label="End Hour" source={innerProps.getSource('endHour')} min={0} max={23} />
                                                            <span>:</span>
                                                            <NumberInput label="End Minute" source={innerProps.getSource('endMinute')} min={0} max={59} />
                                                        </>
                                                    )}
                                                </FormDataConsumer>
                                            </SimpleFormIteratorRoot>
                                        </ArrayInput>
                                    )
                                    : null}
                            </>
                        )}
                    </FormDataConsumer>
                </SimpleFormIteratorWeek>
            </ArrayInput>
            : null
    );
};

const useSquareStore = (id) => {
    const dataProvider = useDataProvider();
    const [squareStore, setSquareStore] = useState();

    useEffect(() => {
        dataProvider.getOne("square_info", { id: id })
            .then(({ data }) => {
                setSquareStore(data);
            })
            .catch(err => console.error(err));
    }, [id]);

    return { squareStore, setSquareStore };
}

const SquareStoreForm = props => {
    const { getValues } = useFormContext();
    const { squareStore } = useSquareStore(getValues('id'));
    return (
        <TextInput label="Location ID" source="squareStore.locationId" defaultValue={squareStore?.locationId} />
    );
};

const useStripeStore = (id) => {
    const dataProvider = useDataProvider();
    const [stripeStore, setStripeStore] = useState();

    const fetchStripeStore = () => dataProvider.getOne("stripe_info", { id: id })
        .then(({ data }) => {
            setStripeStore(data);
        })
        .catch(err => console.error(err));

    useEffect(() => {
        fetchStripeStore();
    }, [id]);

    return { stripeStore, fetchStripeStore };
}

const StripeStoreForm = () => {
    const dataProvider = useDataProvider();
    const { getValues } = useFormContext();
    const { stripeStore, fetchStripeStore } = useStripeStore(getValues('id'));
    const notify = useNotify();

    const handleOnboardAccountClick = async () => {
        const storeId = getValues('id');
        try {
            const {data} = await dataProvider.create("payments/stripe/connected_account/~/onboarding_link", {
                data: {
                    storeId: storeId,
                    refreshUrl: `${window.location.origin}/#/stores/${storeId}/stripe_info`,
                    returnUrl: `${window.location.origin}/#/stores/${storeId}/stripe_info`
                }});
            fetchStripeStore();
            const accountLink = data.id;
            window.open(accountLink, "_blank");
        } catch(err) {
            notify("Error getting onboarding link from Stripe: " + err.message);
        }
    };

    const handleLoginLinkClick = async () => {
        const storeId = getValues('id');
        try {
            const {data} = await dataProvider.create(`payments/stripe/connected_account/~/login_link`, {
                data: { 
                    storeId: storeId
                }});
            const loginLink = data.id;
            window.open(loginLink, '_blank');
        } catch(err) {
            notify("Error getting login link from Stripe: " + err.message);
        }
    };

    return (
        <>
            <TextInput fullWidth label="Stripe Account ID" source="stripeStore.stripeAccountId" defaultValue={stripeStore?.stripeAccountId} />
            <TextInput fullWidth label="Stripe Location ID" source="stripeStore.stripeLocationId" defaultValue={stripeStore?.stripeLocationId} />
            <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={handleOnboardAccountClick}
            >
                Onboard Connected Account
            </Button>
            {stripeStore?.stripeAccountId && <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={handleLoginLinkClick}
                style={{ marginTop: '20px' }}
            >
                Login to Connected Account
            </Button>}
        </>
    );
};

export const StoreEdit = props => {
    const [uploadMode, setUploadMode] = React.useState(true);
    const [imageUploaded, setImageUploaded] = React.useState(false);
    const { save } = useEditController(props);
    const dataProvider = useDataProvider();
    const location = useLocation();
    const notify = useNotify();

    const onSubmit = useCallback(async function ({ schedules, squareStore, stripeStore, ...data }) {
        if (location.pathname.split('/').pop() === 'schedules') {
            try {
                await dataProvider.update('stores_weekly_schedules', {
                    id: data.id,
                    data: { dailySchedules: schedules }
                });
                notify('Store schedules updated successfully');
            } catch (err) {
                console.error(err);
                notify('Error updating store schedules');
            }
        } else if (location.pathname.split('/').pop() === 'square_info') {
            try {
                await dataProvider.update('square_info', {
                    id: data.id,
                    data: {
                        locationId: squareStore?.locationId
                    }
                });
                notify('Square Store information updated successfully');
            } catch (err) {
                console.error(err);
                notify('Error updating Square Store information');
            }
        } else if (location.pathname.split('/').pop() === 'stripe_info') {
            try {
                await dataProvider.update('stripe_info', {
                    id: data.id,
                    data: {
                        stripeAccountId: stripeStore?.stripeAccountId,
                        stripeLocationId: stripeStore?.stripeLocationId
                    }
                });
                notify('Stripe Store information updated successfully');
            } catch (err) {
                console.error(err);
                notify('Error updating Stripe Store information');
            }
        } else {
            save(data);
        }
    }, [dataProvider, location.pathname, notify, save]);
    return (
        <Edit {...props}>
            <TabbedForm
                onSubmit={onSubmit}
                toolbar={<CustomToolbar imageUploaded={imageUploaded} uploadMode={uploadMode} deleteButton={true} />}
                sanitizeEmptyValues
            >
                <TabbedForm.Tab label="Basic Info">
                    <NumberInput label="Store ID" source="storeId" disabled />
                    <StoreInputs />
                    <ImageUpload imageUploaded={imageUploaded} uploadMode={uploadMode}
                        setImageUploaded={setImageUploaded} setUploadMode={setUploadMode}
                        imageSource="storeImageSource" imageFile="storeImageFile"
                        primaryImageUrl="primaryImageUrl" primaryImageThumbnailUrl="primaryImageThumbnailUrl"
                    />
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Schedules" path="schedules">
                    <SchedulesForm />
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Square Info" path="square_info">
                    <SquareStoreForm />
                </TabbedForm.Tab>
                <TabbedForm.Tab label="Stripe Info" path="stripe_info">
                    <StripeStoreForm />
                </TabbedForm.Tab>
            </TabbedForm>
        </Edit>
    );
}

export const CustomToolbar = ({ uploadMode, imageUploaded, deleteButton, ...props }) => (
    <Toolbar {...props} style={{ "justifyContent": "space-between" }}>
        <SaveButton disabled={(!uploadMode && imageUploaded)} />
        {deleteButton && <DeleteButton />}
    </Toolbar>
);