import React, { useCallback, useState, useEffect, useRef } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { useInput } from 'react-admin';
import Editor, { DiffEditor } from '@monaco-editor/react';
import { useLocation } from 'react-router';
import { Difference } from '@mui/icons-material';

/**
 * A JSON editor input component using Monaco Editor with diff mode capability
 * @component
 * 
 * @param {Object} props
 * @param {string} props.label - Label text to display above the editor
 * @param {string} [props.serverError] - Error message from server to display, usually for validation errors
 * @param {(boolean|'auto')} [props.jsonString=true] - Whether the form value should be stored as string
 *                                                     - true: store as string
 *                                                     - false: store as parsed object
 *                                                     - 'auto': determine based on form value type (may cause data inconsistency)
 * @param {string|number} [props.height='30vh'] - Height of the editor
 * @param {string} props.source - Source field name for the input
 * @param {...Object} rest - Props passed to useInput hook from react-admin
 * 
 * @returns {React.ReactElement} JSON editor component with optional diff view
 * 
 * @example
 * <JsonEditorInput 
 *   label="Configuration"
 *   source="config"
 *   jsonString={false}
 *   height="400px"
 * />
 */
export const JsonEditorInput = ({ label, serverError, jsonString = true, height, ...props }) => {
    const location = useLocation();
    const editorRef = useRef(null);
    const monacoRef = useRef(null);
    const [originalValue, setOriginalValue] = useState(null);
    const [editorValue, setEditorValue] = useState("");
    const [isDiffMode, setIsDiffMode] = useState(false);

    const editModelPath = location.pathname + "/" + props.source;
    const originalModelPath = location.pathname + "/" + props.source + "/original";

    const {
        field: { onChange, value },
        fieldState: { error },
        formState: { isSubmitting, isSubmitSuccessful },
        isRequired
    } = useInput({
        ...props,
        validate: value => {
            if (isRequired && !editorValue) return 'Required';
            try {
                JSON.parse(editorValue);
                return undefined; // validation passed
            } catch (e) {
                return e.message;
            }
        }
    });

    // Auto-detect jsonString based on form value type
    if (jsonString === 'auto') {
        jsonString = typeof value === 'string';
    }

    // Initialize editor value and original value
    useEffect(() => {
        if (typeof value === 'string') {
            setOriginalValue(value);
            setEditorValue(value);
        } else {
            const valStr = JSON.stringify(value, null, 4);
            setOriginalValue(valStr);
            setEditorValue(valStr);
        }
    }, []);

    // Watch for form submission and update diff editor's original value
    useEffect(() => {
        if (!isSubmitting && isSubmitSuccessful) {
            setOriginalValue(editorValue);
        }
    }, [isSubmitting, isSubmitSuccessful]);

    const handleEditorChange = useCallback((strValue) => {
        setEditorValue(strValue);

        if (jsonString) { // notify form that the value has changed (since it is string)
            onChange(strValue);
        } else { // notify form that the value has changed only if it is valid JSON
            try {
                // Validate that the content is valid JSON
                JSON.parse(strValue);
                onChange(JSON.parse(strValue));
            } catch (e) {
                // If invalid JSON, set back to original value (to trick form into greying out submit button)
                onChange(JSON.parse(originalValue));
            }
        }
    }, [onChange]);

    function handleEditorDidMount(editor, monaco) {
        editorRef.current = editor;
        monacoRef.current = monaco;
    }

    function handleDiffEditorDidMount(editor, monaco) {
        const modifEditor = editor.getModifiedEditor();
        modifEditor.onDidChangeModelContent(() => handleEditorChange(modifEditor.getValue()));
        editor.getOriginalEditor().setValue(originalValue || '');
    }


    // Validate jsonString prop (otherwise the error is extremely obscure)
    if ((jsonString === true) && value && typeof value === 'object') {
        console.error("JSON Editor's source form is storing an object, despite prop 'jsonString=true'");
        console.log("Source form is storing", value);
        return <span>JSON Editor's source form is storing an object, despite prop 'jsonString=true'</span>;
    } else if ((jsonString === false) && value && typeof value === 'string') {
        console.error("JSON Editor's source form is storing a string, despite prop 'jsonString=false'");
        console.log("Source form is storing", value);
        return <span>JSON Editor's source form is storing a string, despite prop 'jsonString=false'</span>;
    }

    return (
        <Box
            sx={{
                padding: 2,
                marginTop: 1,
                marginBottom: 1,
                backgroundColor: 'background.paper',
                borderRadius: 1,
                border: '1px solid',
                borderColor: error ? 'error.main' : 'rgba(0, 0, 0, 0.23)',
                width: '100%',
                '&:hover': {
                    borderColor: error ? 'error.main' : 'rgba(0, 0, 0, 0.87)',
                },
                transition: 'border-color 200ms cubic-bezier(0.4, 0, 0.2, 1)',
            }}
        >
            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '8px' }}>
                <Typography variant="subtitle2">{label + ": "}</Typography>
                <IconButton size="small" onClick={() => setIsDiffMode(!isDiffMode)}>
                    <Difference />
                </IconButton>
            </div>
            <div style={{ width: '100%', height: height ?? '30vh' }}>
                <style>
                    {`.monaco-editor { position: absolute !important; }`}
                </style>
                {!isDiffMode ?
                    <Editor
                        key="single-editor"
                        path={editModelPath}
                        defaultLanguage="json"
                        defaultValue={editorValue || ''}
                        keepCurrentModel={true}
                        onMount={handleEditorDidMount}
                        onChange={handleEditorChange}
                        options={{
                            ...MONACO_EDITOR_OPTIONS,
                            readOnly: isSubmitting,
                            
                            minimap: {
                                enabled: false,
                            }
                        }}
                    />
                    :
                    <DiffEditor
                        key="diff-editor"
                        original={originalValue || ''}
                        originalModelPath={originalModelPath}
                        modifiedModelPath={editModelPath}
                        keepCurrentOriginalModel={true}
                        keepCurrentModifiedModel={true}
                        onMount={handleDiffEditorDidMount}
                        language="json"
                        options={{
                            ...MONACO_EDITOR_OPTIONS,
                            readOnly: isSubmitting,
                            minimap: {
                                enabled: false,
                            }
                        }}
                    />
                }
            </div>
            {error && <span className="error" style={{ whiteSpace: 'pre-line', color: '#d32f2f' }}>{error.message}</span>}
            {serverError && <span className="error" style={{ whiteSpace: 'pre-line', color: '#d32f2f' }}>{serverError}</span>}
        </Box>
    );
};

const MONACO_EDITOR_OPTIONS = {
    fontSize: 14,
    tabSize: 4,
    useTabStops: true,
    autoIndent: "full",
    scrollBeyondLastLine: false,
    formatOnPaste: true,
    formatOnType: true,
    glyphMargin: true,
    autoClosingBrackets: "always",
    autoClosingQuotes: "always",
    automaticLayout: true
};